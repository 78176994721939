import Dialog from "../shared/Dialog";
import {
  Box, Button, IconButton, InputAdornment, MenuItem, TextField, Typography
} from "@mui/material";
import SelectCom from "../SelectCom";
import {DateField} from "@mui/x-date-pickers";
import {ReactComponent as GiftIcon} from "../../assets/images/gift.svg";
import dayjs from "dayjs";
import * as Yup from "yup";
import {useFormik} from "formik";
import {updateTrainee} from "../../services/TraineeService";
import moment from "moment";
import {getErrorMessage} from "../../enums/ErrorMessages";
import {useMessageContext} from "../../contexts/MessageContext";
import {EquipmentTypes} from "../../enums/EquipmentType";
import {experienceLevels} from "../../enums/ExperienceLevels";
import {useNavigate} from "react-router-dom";
import clients from "../../pages/Clients";

const EditClientDialog = ({navigate, trainee, close, isOpen, reload}) => {

  const {notificationHandler} = useMessageContext();

  const schema = Yup.object().shape({
    name: Yup.string()
        .required('שדה חובה'),
    phoneNumber: Yup.string()
        .required('שדה חובה'),
    email: Yup.string()
        .email("אימייל לא חוקי")
        .required('שדה חובה'),
    dateOfBirth: Yup.string()
        .required('שדה חובה'),
  });

  function formatPhoneNumber(input) {
    // Remove all non-numeric characters
    let cleaned = input.replace(/\D/g, '');

    // Check if the number starts with '0' and replace it with '+972'
    if (cleaned.startsWith('0')) {
      cleaned = '+972' + cleaned.slice(1);
    } else if (!cleaned.startsWith('+972')) {
      // If it's already in international format, ensure it starts correctly
      cleaned = '+972' + cleaned;
    }

    return cleaned;
  }

  const formik = useFormik({
    initialValues: {
      name: trainee ? trainee.name : "",
      phoneNumber: trainee ? trainee.phoneNumber : "",
      email: trainee ? trainee.email : "",
      gender: trainee ? trainee.gender : "null",
      experienceLevel: trainee ? trainee.experienceLevel : "null",
      equipmentType: trainee ? trainee.equipmentType : "null",
      numOfDaysTraining: trainee ? trainee.numOfDaysTraining : 0,
      id: trainee.id,
      expirationDate: trainee.expirationDate ? dayjs(trainee.expirationDate * 1000) : null,
      dateOfBirth: trainee.dateOfBirth ? dayjs(trainee.dateOfBirth * 1000) : null,
      password: "",
      secondPassword: ""
    },
    onSubmit: (values, {resetForm}) => {
      let body = {...values};
      body.gender = body.gender === "null" ? null : body.gender;
      body.experienceLevel = body.experienceLevel === "null" ? null : body.experienceLevel;
      body.equipmentType = body.equipmentType === "null" ? null : body.equipmentType;

      delete body.secondPassword;

      body.dateOfBirth = body.dateOfBirth ? moment(body.dateOfBirth.$d).unix() : null;
      body.expirationDate = body.expirationDate ? moment(body.expirationDate.$d).unix() : null;
      body.phoneNumber = (formatPhoneNumber(body.phoneNumber));
      body.password = body.password === "" ? null : body.password;

      updateTrainee(body)
          .then(() => navigate(`/app/clients`))
          .then(() => reload())
          .then(handleClose)
          .then(() => resetForm())
          .then(() => notificationHandler.success("מתאמן עודכן בהצלחה"))
          .catch(e => notificationHandler.error(getErrorMessage(e.response.data?.errorCode)))
    },
    validationSchema: schema,
    validate: values => {
      let errors = {};

      if (values.password !== values.secondPassword) {
        errors.secondPassword = "סיסמאות לא זהה";
      }

      return errors;
    }});

  const handleClose = () => {
    formik.resetForm({values: ''});
    close();
  }

  return (
      <Dialog close={handleClose} isOpen={isOpen} title={"פרטים אישיים"}>
        <form onSubmit={formik.handleSubmit}>
        <Box sx={{display: "flex", alignItems: "top", justifyContent: "space-between", padding: "40px"}}>
          <Box>
            <Typography>
              *שם מלא:
            </Typography>
            <TextField
                sx={{marginTop: "8px"}}
                fullWidth
                onBlur={formik.handleBlur("name")}
                value={formik.values.name}
                onChange={formik.handleChange("name")}
                id="name"
                variant="outlined"
            />
            {formik.errors.name && formik.touched.name && <Typography sx={{color: "#F16063"}}>{formik.errors.name}</Typography>}
            <Box sx={{display: "flex",marginTop: "24px"}}>
              <Box sx={{ml: "32px"}}>
              <Typography >
                *תאריך לידה:
              </Typography>
              <DateField

                  InputProps={{
                    endAdornment: (<InputAdornment position="end">
                      <IconButton
                          disabled
                          aria-label="height"
                          edge="start"
                      >
                        <GiftIcon/>
                      </IconButton>
                    </InputAdornment>),
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('dateOfBirth', dayjs(value));
                  }}
                  value={formik.values.dateOfBirth}
                  format={"DD/MM/YYYY"}  />
            </Box>

            <SelectCom
                id={"gender"}
                width={"100px"}
                height={"56px"}
                mainItem={"לא נבחר"}
                label={"מגדר"}
                contrast={"black"}
                marginLeft="8px"
                onBlur={formik.handleBlur("gender")}
                value={formik.values.gender}
                onChangeReal={formik.handleChange("gender")}
                defaultValue={"null"}>
              <MenuItem value={"MALE"} sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>זכר</Typography>
              </MenuItem>
              <MenuItem value={"FEMALE"} sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography sx={{marginLeft: "8px"}}>נקבה</Typography>
              </MenuItem>
            </SelectCom>
            </Box>
            <Typography sx={{marginTop: "24px"}}>
              *תאריך סיום:
            </Typography>
            <DateField
                sx={{mb: "24px"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.expirationDate}
                onChange={(value) => {
                  formik.setFieldValue('expirationDate', dayjs(value));
                }}
                format={"DD/MM/YYYY"}  />

            <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <SelectCom
                id={"experienceLevel"}
                height={"56px"}
                width={"170px"}
                mainItem={"לא נבחר"}
                label={"רמת מתאמן"}
                contrast={"black"}
                onBlur={formik.handleBlur("experienceLevel")}
                value={formik.values.experienceLevel}
                onChangeReal={formik.handleChange("experienceLevel")}
                defaultValue={"null"}>
              {experienceLevels.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            <SelectCom
                id={"equipmentType"}
                height={"56px"}
                width={"170px"}
                mainItem={"לא נבחר"}
                label={"ציוד קיים"}
                contrast={"black"}
                onBlur={formik.handleBlur("equipmentType")}
                value={formik.values.equipmentType}
                onChangeReal={formik.handleChange("equipmentType")}
                defaultValue={"null"}>
              {EquipmentTypes.map((res, i) => {
                return <MenuItem key={i} value={res.value} sx={{display: "flex", alignItems: "center"}}>
                  <Typography sx={{marginLeft: "8px"}}>{res.name}</Typography>
                </MenuItem>
              })}
            </SelectCom>
            </Box>
          </Box>

          <Box sx={{marginRight: "80px", paddingLeft: "40px"}}>
            <Typography >
              *מייל:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px", width: "350px", direction: "ltr"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                id="email"
                variant="outlined"
            />
            {formik.errors.email && formik.touched.email && <Typography sx={{color: "#F16063"}}>{formik.errors.email}</Typography>}
            <Typography>
              טלפון:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px", direction: "ltr"}}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                id="phoneNumber"
                variant="outlined"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && <Typography sx={{color: "#F16063"}}>{formik.errors.phoneNumber}</Typography>}

            <Typography>
              מספר אימונים בשבוע:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type={"number"}
                onBlur={formik.handleBlur}
                value={formik.values.numOfDaysTraining}
                onChange={formik.handleChange}
                id="numOfDaysTraining"
                variant="outlined"
            />
            {formik.errors.numOfDaysTraining && formik.touched.numOfDaysTraining && <Typography sx={{color: "#F16063"}}>{formik.errors.numOfDaysTraining}</Typography>}


          </Box>
          <Box sx={{marginRight: "80px", paddingLeft: "40px"}}>
            <Typography>
              *סיסמה:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type="password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
                id="password"
                variant="outlined"
            />
            {formik.errors.password && formik.touched.password && <Typography sx={{color: "#F16063"}}>{formik.errors.password}</Typography>}

            <Typography>
              *סיסמה בשנית:
            </Typography>
            <TextField
                sx={{marginTop: "8px", marginBottom: "20px"}}
                fullWidth
                type="password"
                onBlur={formik.handleBlur}
                value={formik.values.secondPassword}
                onChange={formik.handleChange}
                id="secondPassword"
                variant="outlined"
            />
            {formik.errors.secondPassword && formik.touched.secondPassword && <Typography sx={{color: "#F16063"}}>{formik.errors.secondPassword}</Typography>}
          </Box>
        </Box>
        <Box sx={{paddingBottom: "40px", textAlign: "center"}}>
          <Button
              onClick={handleClose}
              sx={{marginRight: "24px", backgroundColor: "#F1F3F5", color: "black"}}
              variant={"contained"}>
            בטל
          </Button>
          <Button
              disabled={!formik.dirty || !formik.isValid}
              type={"submit"}
              sx={{marginRight: "24px"}}
              variant={"contained"}>
            שמור
          </Button>
        </Box>
        </form>
      </Dialog>
  );
};

export default EditClientDialog;
